import React from "react";
import '../index.css';

const Footer = ()=>{

    return (
        <div className="footer">
            <h2>Designed and Developed with <span aria-label="heart" role={"img"}>❤️</span> by <a href="https://github.com/oracularzebra">Kartikey</a></h2>
        </div>
    )

}
export default Footer;